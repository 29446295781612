import {
  DEFAULT_LOCALE,
  SUPPORTED_LOCALES,
} from "@socol/config/lib/constants/locales";
import { DEFAULT_SEO } from "@socol/config/lib/constants/seo";
import { ALL_THEMES } from "@socol/config/lib/constants/themes";
import type {
  GetStaticPaths,
  GetStaticProps,
  GetStaticPropsContext,
  InferGetStaticPropsType,
} from "next";
import Image from "next/image";
import { Widget } from "@typeform/embed-react";

import React from "react";
import { useWindowSize } from "react-use";

import Header from "@/lib/components/marketing/Header";
import Collapse from "@/lib/components/marketing/Collapse";
import Footer from "@/lib/components/marketing/Footer";

import SocolLogoSVG from "@/lib/ui/img/landing/SocolLogo.svg";
import MenuIconSVG from "@/lib/ui/img/landing/MenuIcon.svg";
import StarkWareSVG from "@/lib/ui/img/landing/StarkWare.svg";
import StarkWareWhiteSVG from "@/lib/ui/img/landing/StarkWareWhite.svg";
import LeftHandSVG from "@/lib/ui/img/landing/LeftHand.svg";
import RightHandSVG from "@/lib/ui/img/landing/RightHand.svg";
import SocolNameSVG from "@/lib/ui/img/landing/SocolName.svg";
import SunSVG from "@/lib/ui/img/landing/Sun.svg";
import MoonSVG from "@/lib/ui/img/landing/Moon.svg";

import AnimocaBrandsSVG from "@/lib/ui/img/landing/AnimocaBrands.svg";
import MechanismCapitalSVG from "@/lib/ui/img/landing/MechanismCapital.svg";
import ThreeArrowsCapitalSVG from "@/lib/ui/img/landing/ThreeArrowsCapital.svg";
import GenblockCapitalSVG from "@/lib/ui/img/landing/GenblockCapital.svg";
import GFCSVG from "@/lib/ui/img/landing/GFC.svg";
import AntiFundSVG from "@/lib/ui/img/landing/AntiFund.svg";
import KronosSVG from "@/lib/ui/img/landing/Kronos.svg";
import DoublePeakSVG from "@/lib/ui/img/landing/DoublePeak.svg";
import DeFianceCapitalSVG from "@/lib/ui/img/landing/DeFianceCapital.svg";

import HeroAnimationLight from "@/lib/ui/img/landing/HeroAnimationLight.png";
import HeroAnimationDark from "@/lib/ui/img/landing/HeroAnimationDark.png";
import SocialCard1Img from "@/lib/ui/img/landing/SocialCard1.png";
import SocialCard2Img from "@/lib/ui/img/landing/SocialCard2.png";
import SocialCard3Img from "@/lib/ui/img/landing/SocialCard3.png";
import SocialCard4Img from "@/lib/ui/img/landing/SocialCard4.png";

export const getStaticPaths: GetStaticPaths = async () => {
  const paths = SUPPORTED_LOCALES.map((theme: string) =>
    ALL_THEMES.map((locale: string) => ({
      params: {
        theme,
        locale,
      },
    }))
  ).flat(2);

  return {
    paths,
    fallback: "blocking",
  };
};

export const getStaticProps: GetStaticProps = async (
  context: GetStaticPropsContext
) => {
  // Pull data from CMS
  // use DatoCMS https://www.datocms.com/docs/next-js
  // https://github.com/datocms/nextjs-demo
  const seo = DEFAULT_SEO?.[DEFAULT_LOCALE];

  return {
    props: {
      data: { seo },
    },
    revalidate: 1,
  };
};

export default function LandingPage(
  props: InferGetStaticPropsType<typeof getStaticProps>
) {
  const [formOpen, setFormOpen] = useState(false);
  const [isShowModal, setIsShowModal] = React.useState(false);
  const { width } = useWindowSize();
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const mode = localStorage.getItem("mode");
    if (mode === "dark") {
      setDarkMode(true);
    }
  }, []);

  const transform = { transform: "matrix(0.97, 0, -0.28, 1, 0, 0)" };

  return (
    <>
      <Head>
        <title>SO-COL: Create and engage with your community</title>
      </Head>
      {!formOpen ? (
        <div
          className={`${
            darkMode ? `text-white bg-grey-900` : `text-grey-900 bg-[#FDF6F3]`
          }`}
        >
          {isShowModal && (
            <Header
              close={() => setIsShowModal(false)}
              darkMode={darkMode}
              onSetFormOpen={() => setFormOpen(true)}
            />
          )}
          <div
            className={`md:pt-[55px] pt-[10px] bg-gradient-to-r z-10 overflow-hidden relative 
              ${
                darkMode
                  ? `from-[#FFC83A]/20 via-[#FF008A]/20 to-[#6100FF]/20`
                  : `from-red-500/30 via-purple-650/30 to-[#4806FF]/30`
              }
              `}
          >
            <div className="relative">
              <header className="flex justify-between xl:px-[105px] md:px-[68px] px-[10px]">
                <SocolLogoSVG className="scale-50 md:scale-100" />
                <div className="flex items-center">
                  <button
                    className={`w-[103px] h-[40px] rounded-[34px] px-[4px] flex items-center bg-red-500/30 scale-50 md:scale-100`}
                    onClick={() => setDarkMode(!darkMode)}
                  >
                    <div
                      className={`${
                        darkMode
                          ? `bg-gradient-to-r from-[#543E54] to-[#3A2D4B] translate-x-[180%]`
                          : `bg-white`
                      } w-[34px] h-[34px] rounded-full flex items-center justify-center transition ease-in-out`}
                    >
                      {darkMode ? <MoonSVG /> : <SunSVG />}
                    </div>
                  </button>
                  {/* <button
                    onClick={() => setIsShowModal(true)}
                    className="ml-[-20px] md:ml-[31px]"
                  >
                    <MenuIconSVG className="scale-50 md:scale-100" />
                  </button> */}
                </div>
              </header>
              <div className="flex justify-center text-center font-title italic max-w-[570px] mx-auto mt-[76px] md:mt-[156px] xl:mt-[124px] relative">
                <div
                  className={`${
                    darkMode ? `bg-[#685252]` : `bg-[#FFEDE6]`
                  } absolute top-[-27%] md:top-[-50%] md:left-[-12%] w-[320px] h-[320px] md:w-[700px] md:h-[700px] rounded-full z-1 blur-[50px] md:blur-[100px]`}
                />
                <div className="z-10 relative">
                  <p className="text-[18px] md:text-[26px] leading-[21px] uppercase">
                    Welcome to the future
                  </p>
                  <h1 className="font-extrabold text-red-500 text-[60px] md:text-[152px] leading-[50px] md:leading-[125px] mx-auto max-w-[230px] md:max-w-none mt-[60px] md:mt-[83px] xl:mt-[31px]">
                    THIS IS SO-COL
                  </h1>
                </div>
              </div>
              <div className="xl:mt-[25px] mt-[18px] flex justify-center relative overflow-hidden">
                <p className="absolute text-[12px] md:text-[14px] leading-[17px] md:leading-[24px] uppercase md:top-[15px] max-w-[209px] md:max-w-none text-center">
                  A new way for fans and creators to collaborate
                </p>
                <div className="h-[185px] md:h-[358px] xl:h-[460px]">
                  <Image
                    src={darkMode ? HeroAnimationDark : HeroAnimationLight}
                    className="mix-blend-multiply opacity-70"
                    layout="fill"
                    objectFit="cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pt-[13px] md:pt-[37px] pb-[26px] md:pb-[82px] text-center">
            <span className="text-[9px] md:text-[18px] leading-[24px]">
              POWERED BY
            </span>
            {darkMode ? (
              <StarkWareWhiteSVG className="inline-block scale-50 md:scale-100 ml-[-40px] md:ml-0" />
            ) : (
              <StarkWareSVG className="inline-block scale-50 md:scale-100 ml-[-40px] md:ml-0" />
            )}

            <h1
              className="font-title text-left sm:text-center uppercase max-w-[282px] mx-auto sm:max-w-none text-[48px] md:text-[60px] xl:text-[84px]
                            md:leading-[63px] xl:leading-[88px] leading-[43px] md:mt-[107px] mt-[45px] md:mt-[60px] xl:mt-[75px]"
              style={{ transform: "matrix(0.95, 0, -0.34, 1, 0, 0)" }}
            >
              What makes us different?
            </h1>
            <div className="flex mt-[58px] md:mt-[95px] xl:mt-[53px] justify-center items-center md:flex-row flex-col">
              <div className="max-w-[330px] text-left">
                <h2 className="font-title text-[24px] md:text-[32px] leading-[27px] italic">
                  For the first time,
                </h2>
                <p className="text-[16px] md:text-[18px] leading-[24px] mt-[24px]">
                  creators and fans can have a two-way interaction, on their
                  terms. No other platform is doing what we do.
                </p>
                {/* <button className="mt-[31px] bg-purple-650 text-white text-[18px] font-title italic w-[65%] xl:w-full py-[8px] rounded-[6px] border border-white">
                  {width > 1280
                    ? "ARE YOU A CREATOR? CLICK HERE"
                    : "CREATORS CLICK HERE"}
                </button> */}
              </div>
              <div className="max-w-[345px] text-left ml-0 md:ml-[84px] md:mt-0 mt-[64px]">
                <h2 className="font-title text-[24px] md:text-[32px] leading-[27px] italic">
                  We’re bridging the gap
                </h2>
                <p className="text-[16px] text-[18px] leading-[24px] mt-[24px]">
                  between creators and fans and between web2 and web3. No one
                  else knows the tech like we do
                </p>
                {/* <button className="mt-[31px] bg-red-500 text-white text-[18px] font-title italic w-[65%] xl:w-full py-[8px] rounded-[6px] border border-white">
                  {width > 1280
                    ? "FIND OUT MORE ABOUT THE TECH"
                    : "MORE ABOUT THE TECH"}
                </button> */}
              </div>
            </div>
            <div className="flex justify-center pt-[130px] overflow-hidden max-w-[820px] mx-auto xl:max-w-none">
              <div className="flex xl:flex-nowrap flex-wrap justify-center md:justify-between xl:justify-center md:min-w-[820px]">
                {collectablesCards.map((card) => (
                  <div
                    className={`xl:px-[5px] max-w-[375px] min-w-[355px] xl:m-0 hover:z-10 xl:hover:mt-[-30px] group transition mb-[5px]`}
                    key={card.id}
                  >
                    <div
                      className={`${
                        darkMode
                          ? `bg-grey-900 xl:bg-[#272727]`
                          : `bg-white xl:bg-[#FDF6F3]`
                      }
                      px-[33px] font-title italic border-4 border-red-500 rounded-[20px] h-[582px] text-left pt-[40px] relative overflow-hidden`}
                    >
                      <h1
                        className={`${
                          darkMode
                            ? `group-hover:text-white`
                            : `group-hover:text-grey-900`
                        }  text-[38px] leading-[82%] tracking-[.05em] mb-[15px] relative text-transparent`}
                        style={{
                          WebkitTextStroke: `1px ${
                            darkMode ? `white` : `black`
                          }`,
                        }}
                      >
                        {card.name}
                      </h1>

                      <p className="text-[14px] leading-[14px] uppercase">
                        {card.text}
                      </p>
                      <div className="absolute left-0 bottom-[-7px]">
                        <Image src={card.image} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <h2 className="font-title text-[29px] leading-[29px] italic uppercase my-[60px] xl:my-[40px] px-[40px]">
              At SO-COL,You’re more than just a fan.
            </h2>
            <p className="text-[20px] xl:text-[22px] leading-[25px] xl:leading-[30px] max-w-[950px] mx-auto px-[25px]">
              We’re bringing you closer to your favourite creators. Share their
              vision. Support a cause. Create change. Together.
            </p>
            <button
              onClick={() => setFormOpen(true)}
              className="mt-[24px] md:mt-[52px] xl:mt-[65px] bg-red-500 text-[25px] md:text-[46px] text-white leading-[23px] md:leading-[43px] font-title italic md:w-[340px] border-[3px] border-white py-[12px] rounded-[6px] hover:text-red-500 hover:bg-white active:border-red-500 px-[10px]"
            >
              SIGN UP NOW
            </button>
          </div>
          <div className="rounded-[40px] overflow-hidden relative">
            <div
              className="absolute top-[-30%] md:top-[-60%] xl:top-[-30%] left-[-230px] xl:left-[-15%] w-[1600px] xl:w-[130%] h-[1600px] xl:h-[1180px] rounded-full 
              bg-gradient-to-r from-red-500/40 xl:from-[#FFC83A]/40 via-purple-650/40 xl:via-[#FF008A]/40 to-[#4806FF]/40 xl:to-[#6100FF]/40 
              opacity-40 xl:opacity-30 blur-[200px] z-0"
            />
            <div className="relative z-10">
              <div className="mx-auto relative sm:pt-[98px] pt-[80px] xl:pt-[120px] pb-[94px] sm:pb-[260px] xl:pb-[220px]">
                <h2
                  className="font-title font-extrabold text-left md:text-center max-w-[250px] mx-auto md:max-w-full italic uppercase md:text-[60px] 
                xl:text-[84px] text-[48px] leading-[43px] mb-[44px] md:mb-0 md:leading-[63px] xl:leading-[88px] xl:text-purple-650"
                >
                  WE'RE MAKING IT
                  <span
                    className={`${
                      darkMode ? `text-white` : `text-grey-900`
                    } text-[32px] xl:text-[44px] md:leading-[63px] xl:leading-[88px] leading-[27px] block mt-[20px] md:mt-0`}
                  >
                    Accessible, Affordable & Eco-friendly
                  </span>
                </h2>
                <p className="mx-auto text-center text-[16px] md:text-[20px] max-w-[218px] md:max-w-[570px] leading-[20px] md:leading-[24px] xl:leading-[26px]">
                  Dive into the world of NFTs without having to pay huge fees.
                  We’re making it easy, and eco-friendly too.
                </p>
                {/* <button className="mt-[45px] mx-auto block md:mt-[55px] xl:mt-[70px] bg-purple-650 text-white text-[23px] md:text-[25px] font-title italic px-[15px] max-w-[250px] py-[8px] rounded-[6px] border-2 border-white">
                  FIND OUT MORE
                </button> */}
                <LeftHandSVG
                  className={`${
                    darkMode ? `text-white` : `text-purple-650`
                  } absolute bottom-[-17%] md:bottom-[-27%] left-0 md:left-[-10%] xl:left-0 hidden md:block`}
                  style={
                    width < 768
                      ? {
                          transform:
                            "scale(0.5) rotate(74deg) translate(-75%, 116%)",
                        }
                      : {}
                  }
                />
                <RightHandSVG
                  style={
                    width < 768
                      ? {
                          transform:
                            "scale(0.5) translate(84%, 2%) rotate(25deg)",
                        }
                      : {}
                  }
                  className={`${
                    darkMode ? `text-white` : `text-purple-650`
                  } absolute top-[47%] right-0 md:right-[-10%] xl:right-0 hidden md:block`}
                />
              </div>
              <div className="max-w-[900px] xl:max-w-[1218px] pb-0 md:pb-[170px] text-center mx-auto md:px-[45px]">
                <h2 className="mb-[80px] md:mb-[44px] xl:mb-[30px] ml-[45px] xl:ml-0 font-title text-left xl:text-center font-extrabold italic uppercase text-[32px] md:text-[60px] leading-[34px] md:leading-[63px] xl:leading-[88px] text-purple-650">
                  Roadmap
                </h2>
                <div className="flex flex-row-reverse md:flex-col justify-center">
                  <div className="flex md:flex-row flex-col pl-[12px] md:pr-[150px] md:justify-between items-end md:items-start md:mr-0 pt-[15px] md:pt-0">
                    {roadmapTop.map((item) => (
                      <div
                        className="flex text-left items-end md:items-start md:flex-row flex-col mb-[97px] md:mb-0 "
                        style={transform}
                      >
                        <span className="block translate-x-[-15px] md:translate-x-0 w-[100px] md:w-[2px] h-[2px] md:h-[120px] border-dashed md:border-b-0 border-b-[2px] border-l-0 md:border-l-[2px] self-end border-purple-650 mb-[16px] md:mb-0 mr-[16px] md:mr-0" />
                        <div className="ml-[20px] max-w-[87px] md:max-w-[210px]">
                          <p className="text-[18px] leading-[21px]">
                            {item.description}
                          </p>
                          <p className="font-title font-extrabold text-[20px] leading-[23px] mt-[15px]">
                            {item.year}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="h-[340px] w-full max-w-[22px] md:max-w-none md:ml-[-25px] w-[106%] bg-[#6C3AF633] md:h-[30px] skew-y-[17deg] md:skew-y-0  md:skew-x-[165deg]">
                    <div className="w-full md:h-full h-[50%] md:w-1/2 bg-purple-650 h-full"></div>
                  </div>
                  <div className="flex md:flex-row flex-col justify-start md:justify-between pt-[135px] md:pt-0 md:pl-[17px] md:ml-[60px] md:ml-0">
                    {roadmapBottom.map((item) => (
                      <div
                        className="mb-[97px] md:mb-0 flex text-left items-end md:items-start md:flex-row flex-col"
                        style={transform}
                      >
                        <span className="md:translate-x-0 translate-x-[-30px] block w-[100px] md:w-[2px] h-[2px] md:h-[120px] border-dashed md:border-b-0 border-b-[2px] border-l-0 md:border-l-[2px] ml-[-100px] md:ml-0 border-purple-650" />
                        <div className="ml-0 md:ml-[10px] max-w-[100px] md:max-w-[243px] mr-[25px] md:mr-0 pt-[20px]">
                          <p className="text-[18px] leading-[21px]">
                            {item.description}
                          </p>
                          <p className="font-title font-extrabold text-[20px] leading-[23px] mt-[15px]">
                            {item.year}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center relative rounded-t-[40px] px-[20px] overflow-hidden">
            <div
              className="absolute top-[-50%] left-[-230px] xl:left-[-15%] w-[1600px] xl:w-[130%] h-[1600px] xl:h-[1180px] rounded-full 
              bg-gradient-to-r from-red-500/40 xl:from-[#FFC83A]/40 via-purple-650/40 xl:via-[#FF008A]/40 to-[#4806FF]/40 xl:to-[#6100FF]/40 
              opacity-40 xl:opacity-30 blur-[200px] z-0"
            />
            <div className="relative z-10">
              <div className="max-w-[600px] xl:max-w-[1218px] mx-auto z-[1000] pt-[58px] md:pt-[95px]">
                <h2
                  className="mb-[55px] md:mb-[80px] text-left md:text-center font-title font-extrabold uppercase text-[48px] leading-[43px] md:text-[60px] md:leading-[88px] text-red-500"
                  style={transform}
                >
                  FREQUENTLY ASKED QUESTIONS
                </h2>
                <div className="flex flex-column flex-wrap justify-center xl:justify-between">
                  <div className="w-[480px]">
                    {questionsMock
                      .filter((_qu, id) => id % 2 === 0)
                      .map((question) => (
                        <Collapse
                          title={question.title}
                          description={question.description}
                        />
                      ))}
                  </div>
                  <div className="w-[480px]">
                    {questionsMock
                      .filter((_qu, id) => id % 2 === 1)
                      .map((question) => (
                        <Collapse
                          title={question.title}
                          description={question.description}
                        />
                      ))}
                  </div>
                </div>
              </div>
              <div className="pt-[43px] md:pt-[90px] pb-[100px] max-w-[824px] w-full mx-auto">
                <h3 className="font-title  pb-[46px] md:pb-[63px] font-semibold italic uppercase text-[20px] md:text-[24px] leading-[17px] text-grey-900 md:text-red-500">
                  OUR PARTNERS AND INVESTORS
                </h3>
                <div className="flex flex-wrap justify-between items-center my-[-18px] w-full h-[420px] md:h-[240px] max-w-[350px] md:max-w-none mx-auto">
                  {ourPartners.map((item) => item.logo)}
                </div>
              </div>
            </div>
          </div>
          <div className="mx-auto text-center">
            <SocolLogoSVG className="scale-[1.3] md:scale-[1.9] mx-auto" />
            <h3 className="font-title py-[35px] md:py-[60px] font-semibold italic uppercase text-[14px] md:text-[24px] leading-[17px]">
              WELCOME TO THE FUTURE
            </h3>
            {/* <button
              className={`${
                darkMode ? `text-grey-900 bg-white` : `text-white bg-[#000]`
              } mb-[25px]  h-[56px] md:h-[84px] text-[18px] md:text-[28px] font-title italic px-[15px] max-w-[300px] py-[8px] rounded-[6px]`}
            >
              JOIN NEWSLETTER
            </button> */}
            {/* <p className="text-[16px] md:text-[18px] pb-[15px] max-w-[200px] md:max-w-none mx-auto">
              Get the latest news and guides from So-Col
            </p> */}
            <div>
              <SocolNameSVG />
            </div>
          </div>
          <div className="md:mt-[-45px]">
            <Footer onSetFormOpen={() => setFormOpen(true)} />
          </div>
        </div>
      ) : (
        <Widget id="MNsMlDzQ" className="absolute w-screen h-screen top-0" />
      )}
    </>
  );
}

const collectablesCards = [
  {
    id: 1,
    name: "SOCIAL COLLECTABLES",
    text: "Use social collectAbles to become a part of your favourite creator's community.",
    image: SocialCard1Img,
    // styles: "mr-[17px]",
  },
  {
    id: 2,
    name: "PREMIUM COLLECTABLES",
    text: "Want to lead your favourite creator's community?  Get premium collectables and have a stake, a say in the community.",
    image: SocialCard2Img,
    // styles: "md:mx-[20px] mt-[-470px] md:mt-0 ml-[23px]",
  },
  {
    id: 3,
    name: "COMMUNITY VOTING",
    text: "Have a direct influence on your creator's community by getting voting rights as you become a part of the inner circle.",
    image: SocialCard3Img,
    // styles: "md:mx-[20px] mt-[-470px] mr-[35px]",
  },
  {
    id: 4,
    name: "ACCESSIBLE TO ALL",
    text: "collectables in no time at all! Best part? No crypto knowledge, or crypto wallet, needed.",
    image: SocialCard4Img,
    // styles: "mt-[-470px] mr-[17px] md:mr-0",
  },
];

const ourPartners = [
  {
    id: 1,
    logo: <AnimocaBrandsSVG className="mr-[30px] my-[30px]" />,
    style: "w-1/4",
  },
  {
    id: 2,
    logo: <MechanismCapitalSVG className="mx-[30px] my-[30px]" />,
    style: "w-1/4",
  },
  {
    id: 3,
    logo: <ThreeArrowsCapitalSVG className="mx-[30px] my-[30px]" />,
    style: "w-1/4",
  },
  {
    id: 4,
    logo: <GenblockCapitalSVG className="mr-[30px] my-[30px]" />,
    style: "w-1/4",
  },
  {
    id: 5,
    logo: <GFCSVG className="mr-[30px] my-[30px]" />,
    style: "w-1/5",
  },
  {
    id: 6,
    logo: <AntiFundSVG className="mr-[30px] my-[30px]" />,
    style: "w-1/5",
  },
  {
    id: 7,
    logo: <KronosSVG className="mr-[30px] my-[30px]" />,
    style: "w-1/5",
  },
  {
    id: 8,
    logo: <DoublePeakSVG className="mr-[30px] my-[30px]" />,
    style: "w-1/5",
  },
  {
    id: 9,
    logo: <DeFianceCapitalSVG className="mr-[30px] my-[30px]" />,
    style: "w-1/5",
  },
];

const roadmapTop = [
  {
    id: 1,
    description: "The SO-COL Idea was Born",
    year: "Q2 2021",
  },
  {
    id: 2,
    description: "Successful Investment Seed Round",
    year: "Q4 2021",
  },
  {
    id: 3,
    description: "Beta testing of MVP Public release of the MVP (1.0.0)",
    year: "Q2 2022",
  },
];

const roadmapBottom = [
  {
    id: 1,
    description: "Core Team Setup",
    year: "Q4 2021",
  },
  {
    id: 2,
    description: "Community Creation",
    year: "Q1 2022",
  },
  {
    id: 3,
    description: "Global Marketplace Launch + More Community Features",
    year: "Q3 2022",
  },
];

const questionsMock = [
  {
    title: "How does it work?",
    description:
      "You sign up, connect your existing social channels and subscribe to channels. You get access to the channels by spending Social Collectables. And you get exclusive access to creators through Premium Collectables - they also give you the ability to put forward proposals in the community.",
  },
  {
    title: "How do I trade my collectables?",
    description:
      "Social and Premium Collectables can be resold on the secondary market within the community. Your personal profile is where you can view your collections, listing, bids (as well as info on proposals).",
  },
  {
    title:
      "What's the difference between Social Collectables and Premium Collectables?",
    description:
      "Social Collectables are used as a currency on the SO-COL platform. Each collectable is like an NFT made by creators (from their own content). Premium Collectables are bespoke collectables designed by artists, for creators. They are only revealed after purchase. They also grant exclusive access and exclusive rewards. And ability to put forward proposals to the community.",
  },
  {
    title: "How do I get started?",
    description:
      "For fans: Connect any of your social media accounts or email to the SO-COL platform. We will automatically create a wallet for you and connect your account. That's it. You are onboarded and ready to join a community. For creators: After connecting your social media accounts we will automatically create a wallet for you and mint your social media content as collectables. Choose the 'creator' option and start creating your community.",
  },
  {
    title: "Do I need a crypto wallet?",
    description:
      "No, you do not need to have a crypto wallet or crypto method of payment set up in order to sign up to SO-COL, or to buy and trade on SO-COL. We're making is accessible for non-crypto users to join and use the benefits of web3. ",
  },
  {
    title: "How can I interact with others in the community?",
    description:
      "The SO-COL platform has a public chat feature with all functionalities of a forum-based platform where you can interact with fellow fans and creators in your community(s). ",
  },
  {
    title: "How do I get exclusive access to creators?",
    description:
      "By spending Premium Collectables, you can gain access to private channels for exclusive engagement and exclusive rewards with your favourite creators. As well as put forward proposals for initiatives led by the community.",
  },
  {
    title: "What are the fees?",
    description:
      "Our fees are plain and simple: 2.5% on every transaction made on the SO-COL platform. Nothing more, nothing less. ",
  },
];
